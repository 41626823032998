import { Component, Inject, Prop } from 'vue-property-decorator'
import ChatMessage from './ChatMessage.vue'
import {
  BaseThreadUserDto,
  bsConstants,
  MessagePostDto,
  MessageThreadDto,
  newDate,
  UserSeeder,
  validInt
} from 'booksprout'
import { ValueLabel, CommonBaseListModuleMixin, inject, MessagePostServiceInterface, MessageThreadServiceInterface } from 'booksprout-app'
import { QScrollArea } from 'quasar'
import BlockUser from './BlockUser.vue'
import { BsInput } from '../../../../components/bs-component-bundle'
import EnterAccountNameDialog from '../../../../components/EnterAccountNameDialog.vue'

type BsQScrollArea = QScrollArea & {
  scrollPercentage: number,
  scroll: {
    vertical: {
      size: number
    }
  }
}

@Component({
  data () {
    return {
      thread: void 0,
      threadRecipient: void 0
    }
  },
  components: {
    ChatMessage
  }
})
export default class MessageThread extends CommonBaseListModuleMixin<MessagePostDto> {
  @Prop({type: Number}) readonly id!: number
  @Prop({type: Number}) readonly loadForUser!: number

  // Comes from ../Index.ts
  @Inject() reloadCount!: () => void

  @inject('MessagePostService')
  crudService!: MessagePostServiceInterface<MessagePostDto>

  @inject('MessageThreadService')
  threadService!: MessageThreadServiceInterface<MessageThreadDto>

  list: MessagePostDto[] = []
  urlModelName = 'message'

  newMessage = ''
  interval: any = void 0
  hasHiddenUnread = false
  firstUnreadPostId = -1
  thread!: MessageThreadDto
  threadRecipient!: BaseThreadUserDto
  unreadCount = 0
  hasManuallyScrolled = false
  lastScrollTime = newDate()
  replyAsOptions: ValueLabel[] = []
  replyAs: ValueLabel = {}
  showReplyAsMenu = false
  isRequestingName = false

  get authorIsBlocked () {
    // Is an author and is not allowed to send messages
    return this.thread.authorUser.id === this.threadUserId && !this.thread.authorUser.canSendMessages
  }

  /**
   * The reviewer is blocked from sending a message because the author needs to update their plan
   */
  get reviewerIsBlocked () {
    // Is an author and author can't accept messages
    return this.threadUserId === this.mimicOrAuthenticatedUser.id && !this.thread.authorUser.canAcceptMessages
  }

  get viewOnly () {
    return validInt(this.loadForUser)
  }

  /**
   * This is the user ID the thread component is being loaded as i.e Is this user OR an admin viewing the thread component as this user.
   */
  get threadUserId () {
    return this.loadForUser || this.mimicOrAuthenticatedUser.id
  }

  get canReplyAs () {
    return this.replyAsOptions.length > 0 && this.thread.authorUser.id === this.threadUserId
  }

  get replyAsId () {
    return this.replyAs.value
  }

  canFlagPost (post: MessagePostDto) {
    const supportUserId = new UserSeeder().getLiveDataObject().BooksproutSupport.id

    return post.state !== bsConstants.MESSAGES.STATE.FLAGGED
      && post.userId !== this.threadUserId
      && post.userId !== supportUserId
  }

  async getData (params?: any): Promise<MessagePostDto[]> {
    return await this.crudService.get({
      threadId: this.id,
      forUserId: this.loadForUser
    }) as unknown as MessagePostDto[]
  }

  ensureAccountName () {
    this.isRequestingName = true
    return new Promise(resolve => {
      if (this.isAuthenticated && !this.mimicOrAuthenticatedUser.name && !this.mimicOrAuthenticatedUser.authorRole) {
        this.$q.dialog({
          component: EnterAccountNameDialog,
          parent: this
        }).onOk(() => {
          this.isRequestingName = false
          resolve({})
        })
      } else {
        this.isRequestingName = false
        resolve({})
      }
    })
  }

  async load (params?: any) {
    if (!this.isRequestingName) { // If the interval to reload ticks, don't load when the name dialog is up.
      this.ensureAccountName().then(() => {
        this.loadThreadAll(params)
      })
    }
  }

  async loadThreadAll (params?: any) {
    // There is no point loading the posts for a thread that is blocked.
    if (this.thread && this.thread.blocked) {
      return
    }

    this.unreadCount = 0
    this.firstUnreadPostId = -1

    this.list = await this.getData(params)

    // Calc the first post which is unread so we know where to show the "unread messages" notification.

    // Tracking unread in the loop via a local var so we don't see the number scrolling in the view. We want to it to just change.
    let unreadCount = 0
    if (this.list !== void 0) {
      for (const post of this.list) {
        if (post.state === this.MODULE_CONSTANTS.MESSAGES.STATE.NEW && post.userId !== this.threadUserId) {
          if (this.firstUnreadPostId === -1) {
            this.firstUnreadPostId = post.id
          }
          unreadCount++
        }
      }
    }
    this.unreadCount = unreadCount
    this.hasHiddenUnread = unreadCount > 0
  }

  handleUnreadMessageIntersection (e: any) {
    if (e.isIntersecting) {
      this.hasHiddenUnread = false
    }
  }

  makeReplyAsId (model: string, id?: number) {
    return model + '_' + (id || -1)
  }

  postMessage () {
    if (this.newMessage) {
      let arcTeamId
      let penNameId

      // Only send as pen name or arc team if this is the author posting on the thread.
      if (this.thread.authorUser.id === this.threadUserId) {
        // Try and get the reply from the pen name first,
        penNameId = this.makeReplyAsId('penName', this.thread.arc.book?.authorPenName?.id) === this.replyAsId
          ? parseInt(this.replyAsId.replace('penName_', ''))
          : void 0
        // otherwise, we'll try and respond from the arc team
        if (penNameId === void 0) {
          arcTeamId = this.makeReplyAsId('arcTeam', this.thread.arc.arcTeam?.id) === this.replyAsId
            ? parseInt(this.replyAsId.replace('arcTeam_', ''))
            : void 0
        }
      }

      this.crudService.create({
        threadId: this.id,
        message: this.newMessage,
        arcTeamId,
        penNameId
      }).then(() => {
        this.scrollToBottom()
        this.newMessage = ''
        return this.load().then(() => {
          const input = (this.$refs.messageInput as BsInput)
          if (input) {
            input.focus()
          }
        }).catch(e => {
          this.showError(e.message)
        })
      }).catch(e => {
        this.showError(e.message)
      })
    }
  }

  doOnScroll (scrollInfo: any) {
    // If we have multiple scroll events triggering in a short amount of time, it means the user has triggered the scroll.
    const previousLastScrollTime = this.lastScrollTime
    this.lastScrollTime = newDate()
    if (this.lastScrollTime.getTime() - previousLastScrollTime.getTime() > 1000) {
      this.hasManuallyScrolled = true
    }

    if (scrollInfo.verticalPercentage === 1) {
      // reset the hasManuallyScrolled as the user won't be looking at older messages
      this.hasManuallyScrolled = false
      // User has scrolled to the bottom of the screen and see the unread messages - hide the UI element.
      this.hasHiddenUnread = false
      // Mark all posts as read as the user has scrolled to the bottom.
      // Now when load is called again, it'll update the firstUnreadPostId to our latest unread message.
      void this.crudService.markAllAsRead(this.id)
    }
  }

  scrollToBottom () {
    const scroller = this.$refs.chatScroller as BsQScrollArea
    if (scroller) {
      // scroller.scrollSize no longer available
      // use scroller.scroll.vertical or scroller.scroll.horizontal etc
      scroller.setScrollPosition('vertical', scroller.scroll.vertical.size, 700)
    }
  }

  markAllAsReadAndReload () {
    this.crudService.markAllAsRead(this.id).then(() => {
      this.load().then(() => {
        this.scrollToBottom()
        this.hasHiddenUnread = false
        this.firstUnreadPostId = -1
      }).catch(e => {
        this.showError(e.message)
      })
    }).catch(e => {
      this.showError(e.message)
    })
  }

  blockUser () {
    const userToBlock = this.threadService.getOtherThreadParticipant(this.thread, this.threadUserId)
    if (userToBlock) {
      this.$q.dialog({
        component: BlockUser,
        parent: this,
        blockUser: userToBlock,
        heading: this.$tc('modules.messages.labels.blockQuestion'),
        yesText: this.$tc('modules.messages.actions.blockUser')
      }).onOk((options: any) => {
        return this.threadService.blockUser(userToBlock.id, options.reportUser).then(() => {
          this.list = []
          return this.loadThread()
        }).catch(e => {
          this.showError(e.message)
        })
      })
    }
  }

  unblockUser () {
    const userToBlock = this.threadService.getOtherThreadParticipant(this.thread, this.threadUserId)
    if (userToBlock) {
      return this.threadService.unblockUser(userToBlock.id).then(() => {
        this.load().then(() => {
          return this.loadThread()
        })
      }).catch(e => {
        this.showError(e.message)
      })
    }
    return
  }

  flagPost (post: MessagePostDto) {
    this.$q.dialog({
      component: BlockUser,
      parent: this,
      heading: this.$tc('modules.messages.labels.flagQuestion'),
      message: post.message,
      yesText: this.$tc('modules.messages.actions.report')
    }).onOk((options: any) => {
      return this.crudService.flag(post.id).then(() => {
        this.load({
          notFirstLoad: true
        }).catch(e => {
          this.showError(e.message)
        })
      }).catch(e => {
        this.showError(e.message)
      })
    })
  }

  loadReplyAs () {
    if (this.viewOnly) return

    this.replyAsOptions = []

    // Sort out reply as stuff.
    /**
     * Can reply as the Arc Team OR Pen name, IF;
     * The pen name of the book is a pen name owned by the current user
     * The current user is an ARC service or publisher
     */
    const canReplyAsBoth = this.thread.arc.book?.authorPenName?.userId === this.threadUserId &&
      this.mimicOrAuthenticatedUser.authorRole === this.MODULE_CONSTANTS.USER.ROLES.AUTHOR.PUBLISHER

    /**
     * Else, reply as Arc Team, IF; the account that created this ARC is a publisher account
     * this ARC is for a book they didn't write
     */
    const canReplyAsArcTeam = canReplyAsBoth || (
      this.thread.arc.book?.authorPenName?.userId === void 0 &&
      this.mimicOrAuthenticatedUser.authorRole === this.MODULE_CONSTANTS.USER.ROLES.AUTHOR.PUBLISHER
    )

    /**
     * Else reply as pen name that wrote the book
     */
    const canReplyAsPenName = canReplyAsBoth || !canReplyAsArcTeam

    if (canReplyAsArcTeam) {
      this.replyAsOptions.push(new ValueLabel(this.makeReplyAsId('arcTeam', this.thread.arc.arcTeam?.id), this.thread.arc.arcTeam?.name, this.thread.arc.arcTeam?.image))
      this.replyAs = this.replyAsOptions.find(f => f.value === this.makeReplyAsId('arcTeam', this.thread.arc.arcTeam?.id)) || new ValueLabel()
    }

    if (canReplyAsPenName) {
      this.replyAsOptions.push(new ValueLabel('penName_' + this.thread.arc.book?.authorPenName?.id, this.thread.arc.book?.authorPenName?.name, this.thread.arc.book?.authorPenName?.image))
      this.replyAs = this.replyAsOptions.find(f => f.value === this.makeReplyAsId('penName', this.thread.arc.book?.authorPenName?.id)) || new ValueLabel()
    }
  }

  loadThread () {
    return this.threadService.getByIdForUser(this.id, this.loadForUser).then(thread => {
      if (thread) {
        this.thread = thread
        this.loadReplyAs()
        const threadRecipient = this.threadService.getOtherThreadParticipant(thread, this.threadUserId)
        if (threadRecipient) {
          this.threadRecipient = threadRecipient
        }
      }
    }).catch(e => {
      this.showError(e.message)
    })
  }

  requestHelp () {
    this.$q.dialog({
      component: this.$bs.confirmDialog,
      parent: this,
      heading: this.$tc('modules.messages.labels.requestSupport'),
      bodyHtml: this.$tc('modules.messages.labels.requestSupportMessage'),
      noText: this.$tc('system.actions.cancel'),
      yesText: this.$tc('modules.messages.actions.requestHelp'),
      invertOptions: true
    }).onOk(() => {
      this.$q.loading.show()
      this.threadService.getHelp(this.thread.id).then(() => {
        this.$q.loading.hide()
        return this.load()
      }).catch(e => {
        this.$q.loading.hide()
        this.showError(e.message)
      })
    })
  }

  onShowReplyAsMenuClick () {
    if (this.replyAsOptions.length > 1) {
      this.showReplyAsMenu = !this.showReplyAsMenu
    } else {
      this.showReplyAsMenu = false
    }
  }

  async mounted () {
    await this.loadThread()
    if (!this.viewOnly) {
      if (this.mimicOrAuthenticatedUser.id === this.threadRecipient.id) {
        await this.threadService.markAsRead(this.id)
      }

      await this.reloadCount()

      this.interval = setInterval(() => {
        this.load({
          notFirstLoad: true
        })
      }, 10000)
    }
  }

  updated () {
    setTimeout(() => {
      this.scrollToBottom()
    }, 200)
  }

  beforeDestroy () {
    clearInterval(this.interval)
  }
}
