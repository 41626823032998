import { Component, Prop } from 'vue-property-decorator'
import { QDialog } from 'quasar'
import { BaseMixin } from 'src/components/mixins/base'
import { ArcDto } from 'booksprout'
import AddArcLinks from '../modals/AddLinks.vue'

@Component({
  components: {
    AddArcLinks
  }
})
export default class AddArcLinksDialog extends BaseMixin {
  @Prop({ type: Object}) readonly arc!: ArcDto
  @Prop({ type: Boolean }) readonly showAll!: boolean
  @Prop({ type: Number }) readonly selectedSite!: number

  get dialog (): QDialog {
    return this.$refs.addArcLinksDialog as QDialog
  }

  show () {
    this.dialog.show()
  }

  hide () {
    this.dialog.hide()
  }

  onDialogHide () {
    this.$emit('hide')
  }

  cancel () {
    this.hide()
  }

  async confirm () {
    this.$emit('ok')
    this.hide()
  }
}
